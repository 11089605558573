import React from "react";
import { request } from "./APIservice";
import { URL_GET_UNIQA_AGE_PRICES } from "../config/env";
import { GET } from "../utils/constants/api";
import IconCSCGO from "../images/navbar_menu/KaskoGO_icon.svg";
import IconGO from "../images/navbar_menu/GO_icon.svg";
import IconCSC from "../images/navbar_menu/Kasko_icon.svg";
import IconTRA from "../images/navbar_menu/Travel_icon.svg";
import IconPRP from "../images/navbar_menu/Imushtestvo_icon.svg";
import IconMTN from "../images/navbar_menu/Planinska_icon.svg";
import IconEXT from "../images/navbar_menu/Ekstremni_icon.svg";
import IconHLT from "../images/navbar_menu/Bolnichen_icon.svg";
import IconLFE from "../images/navbar_menu/Jivot_icon.svg";
import IconLFI from "../images/navbar_menu/Lfi_icon.svg";
import IconHWP from "../images/navbar_menu/HWP.svg";
import IconCI from "../images/navbar_menu/CI.svg";

export default class ProductsService {
  static getProductsMenuIcons(code) {
    switch (code) {
      case "CSC+GO":
        return <IconCSCGO className="navbar-icon" />;
      case "GO":
        return <IconGO className="navbar-icon" />;
      case "CSC":
        return <IconCSC className="navbar-icon" />;
      case "TRA":
        return <IconTRA className="navbar-icon" />;
      case "PRP":
        return <IconPRP className="navbar-icon" />;
      case "MTN":
        return <IconMTN className="navbar-icon" />;
      case "EXT":
        return <IconEXT className="navbar-icon" />;
      case "HLT":
        return <IconHLT className="navbar-icon" />;
      case "LFE":
        return <IconLFE className="navbar-icon" />;
      case "HWP":
        return <IconHWP className="navbar-icon" />;
      case "LFI":
        return <IconLFI className="navbar-icon" />;
      case "CI":
        return <IconCI className="navbar-icon" />;
      case "HealthProducts":
        return <IconHLT className="navbar-icon" />;
      case "LifeProducts":
        return <IconLFE className="navbar-icon" />;
      case "CompanyProducts":
        return <IconCI className="navbar-icon" />;
      default:
        return null;
    }
  }

  static getProductName(code, language = "bg") {
    switch (code) {
      case "CSC+GO":
        switch (language) {
          case "bg":
            return "Каско + Гражданска";
          case "en":
            return "Casco Insurance + MTPL";
          default:
            return "";
        }
      case "GO":
        switch (language) {
          case "bg":
            return "Гражданска отговорност";
          case "en":
            return "MTPL";
          default:
            return "";
        }
      case "CSC":
        switch (language) {
          case "bg":
            return "Автокаско";
          case "en":
            return "Casco Insurance";
          default:
            return "";
        }
      case "TRA":
        switch (language) {
          case "bg":
            return "Пътуване в чужбина";
          case "en":
            return "Travel Insurance";
          default:
            return "";
        }
      case "PRP":
        switch (language) {
          case "bg":
            return "Застраховка имущество";
          case "en":
            return "Property Insurance";
          default:
            return "";
        }
      case "MTN":
        switch (language) {
          case "bg":
            return "Планинска застраховка";
          case "en":
            return "Mountain Insurance";
          default:
            return "";
        }
      case "EXT":
        switch (language) {
          case "bg":
            return "Екстремни спортове";
          case "en":
            return "Extreme sports";
          default:
            return "";
        }
      case "HLT":
        switch (language) {
          case "bg":
            return "Здравна застраховка Уника";
          case "en":
            return "Health Insurance";
          default:
            return "";
        }
      case "LFE":
        switch (language) {
          case "bg":
            return "Срочна Застраховка Живот";
          case "en":
            return "Life Insurance";
          default:
            return "";
        }

      case "LFI":
        switch (language) {
          case "bg":
            return "Инвестиционна Застраховка Живот";
          case "en":
            return "Investing Life Insurance";
          default:
            return "";
        }

      case "HWP":
        switch (language) {
          case "bg":
            return "Здравна застраховка Метлайф";
          case "en":
            return "Health with Priority";
          default:
            return "";
        }
      case "HCL":
        switch (language) {
          case "bg":
            return "Здравна застраховка Булстрад";
          case "en":
            return "Health with Priority HEALTH CLUB";
          default:
            return "";
        }
      case "CI":
        switch (language) {
          case "bg":
            return "Фирмена Застраховка Живот";
          case "en":
            return "Company Insurance Life";
          default:
            return "";
        }
      case "HIS":
        return "История на щети";
      case "GOV":
        return "Гражданска отговорност - вноски";
      case "KID":
        return "Детска";
      case "MED":
        return "Лечение без Граници";
      case "GLO":
        switch (language) {
          case "bg":
            return "Проверка за Глоби от КАТ";
          case "en":
            return "Check for Traffic Fines";
          default:
            return "";
        }
      case "KLE":
        switch (language) {
          case "bg":
            return "Кредитни консултации";
          case "en":
            return "Credit counseling";
          default:
            return "";
        }
      case "VIG":
        switch (language) {
          case "bg":
            return "Електронна винетка";
          case "en":
            return "Electronic vignette";
          default:
            return "";
        }
      case "CRH":
        switch (language) {
          case "bg":
            return "Проверка за щети";
          case "en":
            return "Check for damage";
          default:
            return "";
        }
      case "HealthProducts":
        switch (language) {
          case "bg":
            return "Здравни застраховки";
          case "en":
            return "Health insurances";
          default:
            return "";
        }
      case "LifeProducts":
        switch (language) {
          case "bg":
            return "ЗАСТРАХОВКИ ЖИВОТ";
          case "en":
            return "Life Insurances";
          default:
            return "";
        }
      case "CompanyProducts":
        switch (language) {
          case "bg":
            return "ФИРМЕНИ ЗАСТРАХОВКИ";
          case "en":
            return "Company Insurances";
          default:
            return "";
        }
      case "AutoProducts":
        switch (language) {
          case "bg":
            return "АВТОМОБИЛНИ ЗАСТРАХОВКИ";
          case "en":
            return "Auto Insurances";
          default:
            return "";
        }
      case "CHCL":
        switch (language) {
          case "bg":
            return "ЗДРАВНА ЗАСТРАХОВКА БУЛСТРАД";
          case "en":
            return "Corporate health insurance HEALTH CLUB";
          default:
            return "";
        }
      case "HCEF":
        switch (language) {
          case "bg":
            return "Допълнително Здравно Булстрад";
          case "en":
            return "Additional health insurance Bulstrad";
          default:
            return "";
        }
      default:
        return "";
    }
  }

  static async getQuniqaPrices() {
    try {
      const response = await request(GET, URL_GET_UNIQA_AGE_PRICES, {}, null, null, true);
      const { data } = response || {};
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  }
}
