import environmentUrls from "./environment";

export const env = process.env.GATSBY_ENV || "DEVELOPMENT";

const { urlPrefix, urlFibank, magicLinkRedirect, webSocketPrefix, chatGPTWebSocket } = environmentUrls;

// if it doesn't match backend version, there is an error of incompatibility
export const CLIENT_VERSION = 20;
export const isProduction = env === "PRODUCTION";

// virtual pos redirect url
export const API_URL = urlPrefix;
export const URL_FIBANK = urlFibank;
export const URL_MAGIC_LINK_PREFIX = magicLinkRedirect;
export const isOneclickPaymentEnabled = true;

// websocket endpoints
export const URL_WS_PURCHASE_FULFILLED = `${webSocketPrefix}/purchase/isFulfilled`;
export const URL_WS_ACTIONS_STATUS = `${webSocketPrefix}/actionsStatus`;

// chatGPT webSocket
export const CHAT_GPT_WEB_SOCKET = `${chatGPTWebSocket}`;

// payment refactoring endpoints
export const URL_CREATE_PAYMENT = `${urlPrefix}/payment/create`;

export const URL_MYPOS_REDIRECT = `${urlPrefix}/payment/mypos/startTransaction`;

export const URL_IRIS_REDIRECT = `${urlPrefix}/payment/irisPay/startTransaction`;

export const URL_ACTION_STATUS = `${urlPrefix}/actionsStatus`;

// User Profile endpoints
export const URL_GET_CAR_DAMAGE_INFORMATION = `${urlPrefix}/car-damage`;
export const URL_GET_USER_VIGNETTES = `${urlPrefix}/user-vignettes`;
export const URL_GET_SAVED_GLO_RESULTS = `${urlPrefix}/user-fines-search-history`;

// api endpoints
export const URL_FCM_TOKEN = `${urlPrefix}/user/saveFcmToken`;

export const URL_POLICY_UPDATE_REC_PMT = `${urlPrefix}/policy/updateRecPaymentData`;

export const URL_RETRIVE_CAR_PRICE = `${urlPrefix}/pricing/verify-casco-price`;

// TODO - vivacom
export const BOLERON_HOME_URL = "https://www.boleron.bg";

export const URL_VERSION = `${urlPrefix}/supportedClientVersions`;
export const URL_VEHICLE_DATA = `${urlPrefix}/carutils/vehicleData`;
export const URL_VEHICLE_DATA_SERVICES = `${urlPrefix}/carutils/vehicleDataServices`;

// Service URLs
export const PRODUCT_SERVICE_URL = `${urlPrefix}/products`;
export const QUOTE_SERVICE_URL = `${urlPrefix}/quote`;

export const URL_GET_PAYMENTS = `${urlPrefix}/payments`;

export const URL_GET_DUE_PAYMENTS = `${urlPrefix}/duePayments`;

export const URL_GET_DUE_PAYMENTS_PAID = `${urlPrefix}/duePaymentsPaid`;

export const URL_PAYMENT_REQUEST = `${urlPrefix}/payment/request`;

export const URL_PAYMENT_REFUND = `${urlPrefix}/payment/refund`;
export const URL_REPORT_ERROR = `${urlPrefix}/logImportantError`;

export const URL_GO_EUROINS = `${urlPrefix}/go/euroins`;

export const URL_GO_DALLBOGG = `${urlPrefix}/go/dallbogg`;

export const URL_GO_OZK = `${urlPrefix}/go/ozk`;

export const URL_GO_DZI = `${urlPrefix}/go/dzi`;

export const URL_GO_BULSTRAD = `${urlPrefix}/go/bulstrad`;

export const URL_GO_BULINS = `${urlPrefix}/go/bulins`;

export const URL_GO_GENERALI = `${urlPrefix}/go/generali`;

export const URL_GO_UNIQA = `${urlPrefix}/go/uniqa`;

export const URL_GO_ALLIANZ = `${urlPrefix}/go/allianz`;

export const URL_GO_LEVINS = `${urlPrefix}/go/levins`;

export const URL_GO_ARMEEC = `${urlPrefix}/go/armeec`;

export const URL_EXTERNAL_GO = `${urlPrefix}/external/go`;

export const URL_USER_CHECK_BLACKLISTED = `${urlPrefix}/user/checkBlackList`;

export const USER_SERVICE_URL = `${urlPrefix}/user`;

export const POLICY_SERVICE_URL = `${urlPrefix}/policy/create`;

export const POLICIES_URL = `${urlPrefix}/policies`;

export const UNPAID_FOLLOWING_URL = `${urlPrefix}/policies/unpaid-following-duepayment`;

export const URL_GET_FILE = `${urlPrefix}/documents-storage`;

export const URL_GET_ADDITIONAL_FILE = `${urlPrefix}/documents-storage/additional-file`;

export const PDF_SERVICE_URL = `${urlPrefix}/documents-storage`;

export const GET_POLICY = `${urlPrefix}/policy`;

export const POST_SUBSCRIBE_EMAIL_URL = `${urlPrefix}/subscribe`;

export const GET_RESTRICTED_POLICY = `${urlPrefix}/restricted-policy`;

export const GET_RESTRICTED_POLICY_BY_REQUEST_ID = `${urlPrefix}/restricted-policy-by-requestId`;

export const GET_POLICY_BY_GROUP_REQUEST_ID = `${urlPrefix}/policy-by-groupRequestId`;

export const GET_ACTIVE_CSC_GO_POLICIES = `${urlPrefix}/csc-go-policies`;

export const GET_IS_POLICY_RENEWED = `${urlPrefix}/policy/is-policy-renewed`;

export const PAYMENT_TOKEN_URL = `${urlPrefix}/payment/token`;

export const URL_POLICY_REQUEST = `${urlPrefix}/policy/request`;

export const URL_SETTINGS = `${urlPrefix}/settings`;

export const URL_UNIQA_PRICES = `${urlPrefix}/extquotes/TRA`;

export const URL_UNIQA_LIFE_PRICES = `${urlPrefix}/uniqaLife/prices`;

export const URL_EUROINS_PRICES = `${urlPrefix}/pricing/travel/euroins`;

export const URL_LEVINS_TRAVEL_PRICES = `${urlPrefix}/travel/levins`;

export const URL_TRAVEL_FINAL_PRICE = `${urlPrefix}/pricing/travelFinal`;

export const URL_TRAVEL_PRICE = `${urlPrefix}/pricing/travel`;

export const URL_BULSTRAD_TRAVEL_PRICES = `${urlPrefix}/travel/bulstrad`;

export const URL_ALLIANZ_TRAVEL_PRICES = `${urlPrefix}/travel/allianz`;

export const URL_EUROINS_MOUNTAIN_PRICES = `${urlPrefix}/pricing/mountain/euroins`;

export const URL_UNIQA_MOUNTAIN_PRICES = `${urlPrefix}/pricing/mountain/uniqa`;

export const URL_BULSTRAD_MOUNTAIN_PRICES = `${urlPrefix}/pricing/mountain/bulstrad`;

export const URL_EUROINS_EXTREME_PRICES = `${urlPrefix}/pricing/extreme/euroins`;

export const URL_UNIQA_EXTREME_PRICES = `${urlPrefix}/pricing/extreme/uniqa`;

export const URL_PRODUCT_QUOTE = `${urlPrefix}/quotes`;

export const URL_PRP_PRICING = `${urlPrefix}/pricing/property`;

export const URL_USER_VERIFY = `${urlPrefix}/user/verify`;

export const URL_USER_CUSTOM_TOKEN = `${urlPrefix}/user/getCustomToken`;

export const URL_USER_WELCOME = `${urlPrefix}/user/welcome`;

export const URL_MARKETING_CONSENT = `${urlPrefix}/marketingConsent`;

export const URL_PAYMENT_START_TRANSACTION = `${urlPrefix}/payment/startTransaction`;

export const URL_PAYMENT_START_ONECLICK = `${urlPrefix}/payment/startTransactionOneClick`;

export const URL_GET_UNIQA_AGE_PRICES = `${urlPrefix}/product/uniqaHealth/prices`;

export const URL_PAYMENT_START_RP = `${urlPrefix}/payment/beginTransactionRP`;
export const URL_MAKE_PAYMENT_WITH_EXISTING_CARD = `${urlPrefix}/payment/make-payment-with-existing-card`;

export const URL_PAYMENT_CHECK_TRANSACTION = `${urlPrefix}/payment/checkTransaction`;

export const VIN_URL = `${urlPrefix}/utils/decodeVinRestricted`;

export const VIN_PRICE_RANGE_URL = `${urlPrefix}/utils/priceRangeData`;
export const ALLIANZ_PRICE_RANGE_URL = `${urlPrefix}/cascoAndGo/allianz`;
export const URL_BULSTRAD_CASCO_OFFER = `${urlPrefix}/pricing/casco/bulstrad`;
export const URL_EUROINS_CASCO_OFFER = `${urlPrefix}/pricing/casco/euroins`;
export const URL_LEVINS_CASCO_OFFER = `${urlPrefix}/pricing/casco/levins`;
export const URL_GENERALI_CASCO_OFFER = `${urlPrefix}/pricing/casco/generali`;
export const URL_BULINS_CASCO_OFFER = `${urlPrefix}/pricing/casco/bulins`;
export const URL_DZI_CASCO_OFFER = `${urlPrefix}/pricing/casco/dzi`;
export const URL_ARMEEC_CASCO_OFFER = `${urlPrefix}/pricing/casco/armeec`;
export const URL_GENERALI_GOCASCO_OFFER = `${urlPrefix}/cascoAndGo/generali`;
export const URL_CASCO_IS_RISKY_AUTO = `${urlPrefix}/casco/risky-auto`;
export const VIN_PRICE_RANGE_WITH_DATA_URL = `${urlPrefix}/utils/priceRangeManualData`;
export const URL_BULSTRAD_EXAMPLE_OFFER = `${urlPrefix}/quote/bulstrad/casco`;
export const URL_UPDATE_SAVED_CAR = `${urlPrefix}/carutils/updateSavedCar`;
export const URL_TRAVEL_UNIQA_VERIFY_PRICE = `${urlPrefix}/quotes/uniqa/travelFinalPrice`;
export const URL_TRAVEL_EUROINS_VERIFY_PRICE = `${urlPrefix}/quotes/euroins/travelFinalPrice`;
export const URL_TRAVEL_LEVINS_VERIFY_PRICE = `${urlPrefix}/quotes/levins/travelFinalPrice`;
export const URL_TRAVEL_BULSTRAD_VERIFY_PRICE = `${urlPrefix}/quotes/bulstrad/travelFinalPrice`;
export const URL_TRAVEL_ALLIANZ_VERIFY_PRICE = `${urlPrefix}/quotes/allianz/travelFinalPrice`;

export const URL_MTN_BULSTRAD_VERIFY_PRICE = `${urlPrefix}/pricing/mountainFinal/bulstrad`;
export const URL_MTN_EUROINS_VERIFY_PRICE = `${urlPrefix}/pricing/mountainFinal/euroins`;
export const URL_MTN_UNIQA_VERIFY_PRICE = `${urlPrefix}/pricing/mountainFinal/uniqa`;

export const URL_EXT_EUROINS_VERIFY_PRICE = `${urlPrefix}/pricing/extremeFinal/euroins`;
export const URL_EXT_UNIQA_VERIFY_PRICE = `${urlPrefix}/pricing/extremeFinal/uniqa`;

// export const URL_UNIQA_HEALTH_VERIFY_PRICE = `${urlPrefix}/quotes/uniqa/getHealthComfortPremium`;
export const URL_IRIS_BG_PAYMENT = `${urlPrefix}//payment/irisStartTransaction`;
export const QR_PAYMENT_STATUS_URL = `${urlPrefix}/payment/irisCheckTransaction`;
export const URL_SETTLE_START_PAYMENT = `${urlPrefix}/payment/settleStartTransaction`;
export const URL_SETTLE_PAYMENT_STATUS = `${urlPrefix}/payment/settleTransactionStatus`;

export const URL_NEXT_DUE_PAYMENT = `${urlPrefix}/payment/nextDuePayment`;
export const GO_INSTALLMENTS = `${urlPrefix}/go/installments`;
export const MAGIC_LINK_URL = `${urlPrefix}/user/sendSignInLink`;
export const PROMO_CODE_URL = `${urlPrefix}/promo-code`;
export const URL_CAPTCHAS_SAVE_DATA = `${urlPrefix}/external/saveCaptchaCarData`;
export const URL_CAPTCHAS_SEND_EMAIL = `${urlPrefix}/external/sendResultsAutoService`;
export const URL_PAYMENT_CONFIRM_AND_POLICY = `${urlPrefix}/confirmPaymentAndPolicy`;
export const URL_ADD_PAYMENT_AND_CONFIRM_POLICY = `${urlPrefix}/addPaymentAndConfirmPolicy`;
export const URL_PAYMENT_CONFIRM_FALSE = `${urlPrefix}/payment/confirmFalsePayment`;
export const URL_PAYMENT_CUSTOMER_STATUS = `${urlPrefix}/installment/customerStatus`;

export const URL_PAYMENT_FIND_BY_ID = `${urlPrefix}/payment/findById`;
export const URL_PAYMENT_IF_ALREADY_CONFIRMED = `${urlPrefix}/payment/checkIfConfirmed`;
export const URL_SPEEDY = `${urlPrefix}/speedy/`;
export const URL_EKONT = `${urlPrefix}/econt/`;
export const MYPOS_START_TRANSACTION = `${urlPrefix}/payment/mypos/startPurchaseTransaction`;
export const URL_IMPORTANT_EVENT = `${urlPrefix}/logImportantEvent`;
export const URL_VIN_DAMAGES_STATUS = `${urlPrefix}/vin/status?vin=`;
export const URL_VIN_DAMAGES_DATA = `${urlPrefix}/vin/info?vin=`;
export const URL_UPLOAD_PICTURE_TO_STORAGE = `${urlPrefix}/documents-storage/upload-picture`;
export const URL_VACATIONS = `${urlPrefix}/vacations`;
export const URL_WORKTIMEGO = `${urlPrefix}/workTimeGO`;

export const URL_LFI_PROFITABILITY = `${urlPrefix}/metlife/lfi/profitability`;
export const URL_LIFE_HEALTH_PRICES = `${urlPrefix}/pricing/lifeHealth`;
export const URL_LIFE_HEALTH_PRICES_FINAL = `${urlPrefix}/pricing/lifeHealthFinal`;

export const URL_SEND_REFERRAL = `${urlPrefix}/send-referral`;
export const URL_CHECK_REFERRAL = `${urlPrefix}/check-referral`;

export const URL_VALIDATE_USER_SIX_DIGIT_CODE = `${urlPrefix}/user/validate-six-digit-code`;
