import mergeByKey from "array-merge-by-key";
import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { getDocumentsObj } from "../config/activeProducts/helpers";

const config = process.env.GATSBY_CONFIGURATION || "boleron";

const commonDocumentsConfigArray = {
  boleron: {
    platformTermsUrl: "https://devdiag558.blob.core.windows.net/documents/BOLERON_OU.pdf",
    privacyPolicyUrl: "https://devdiag558.blob.core.windows.net/documents/BOLERON_LD_V1.pdf",
    infoUserInsUrl: "https://devdiag558.blob.core.windows.net/documents/INFO_USER_INS.pdf",
    vDogUrl: "https://devdiag558.blob.core.windows.net/documents/VDOG_20230822.pdf",
    paymentTermsUrl: "https://devdiag558.blob.core.windows.net/documents/PAYMENT_20191010.pdf"
  },
  vivacom: {
    platformTermsUrl: "https://devdiag558.blob.core.windows.net/vivacom-documents/OU.pdf",
    platformTermsName: "Общи условия",

    privacyPolicyUrl:
      "https://www.vivacom.bg/bg/files/19640-politika-za-poveritelnost-i-zashtita-na-lichnite-danni-obrabotvani-vyv-vivakom-bylgarija-ead-pri-i-po-povod-predostavjaneto-na-elektronni-syobshtitelni-uslugi.pdf",

    privacyPolicyName: "Политика за защита на личните данни",
    privacyPolicyUrl2:
      "https://www.vivacom.bg/bg/files/19660-politika-za-poveritelnost-i-zashtita-na-lichnite-danni-na-sobstvenicite-na-motorni-prevozni-sredstva-za-koito-treti-lica-skluchvat-zastrahovki-grajdanska-otgovornost-s-posrednichestvoto-na-vivakom-bylgarija-ead.pdf",
    privacyPolicy2Name: "Политика за защита на личните данни - Гражданска отговорност",

    infoUserInsUrl: "https://devdiag558.blob.core.windows.net/vivacom-documents/INFO_USER_INS.pdf",
    vDogUrl: "https://devdiag558.blob.core.windows.net/vivacom-documents/VDOG_VIVACOM.pdf",
    paymentTermsUrl: "https://devdiag558.blob.core.windows.net/vivacom-documents/PAYMENT.pdf"
  }
};

export class SettingsStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "SettingsStore",
      properties: ["products", "insurers", "platformTermsUrl", "privacyPolicyUrl", "infoUserInsUrl", "vDogUrl", "paymentTermsUrl", "ipAddress"],
      storage: typeof document !== "undefined" ? window.sessionStorage : null
    });
  }

  products = [
    {
      id: "CSC+GO",
      name: "Каско + Гражданска отговорност",
      active: true,
      insurers: [
        {
          id: "allianz",
          ...getDocumentsObj("CSC+GO", "allianz")
          //    predogUrl: "" //todo
        },
        {
          id: "armeec",
          ...getDocumentsObj("CSC+GO", "armeec")
        },
        {
          id: "bulins",
          ...getDocumentsObj("CSC+GO", "bulins")
        },
        {
          id: "bulstrad",
          ...getDocumentsObj("CSC+GO", "bulstrad")
        },
        {
          id: "dzi",
          ...getDocumentsObj("CSC+GO", "dzi")
        },
        {
          id: "euroins",
          ...getDocumentsObj("CSC+GO", "euroins")
        },
        {
          id: "generali",
          ...getDocumentsObj("CSC+GO", "generali")
        },
        {
          id: "levins",
          ...getDocumentsObj("CSC+GO", "levins")
        }
      ]
    },
    {
      id: "GO",
      name: "Гражданска отговорност",
      active: true,
      insurers: [
        {
          id: "allianz",
          ...getDocumentsObj("GO", "allianz")
        },
        {
          id: "bulins",
          ...getDocumentsObj("GO", "bulins")
        },
        {
          id: "bulstrad",
          ...getDocumentsObj("GO", "bulstrad")
        },
        {
          id: "dallbogg",
          ...getDocumentsObj("GO", "dallbogg")
        },
        {
          id: "dzi",
          ...getDocumentsObj("GO", "dzi")
        },
        {
          id: "euroins",
          ...getDocumentsObj("GO", "euroins")
        },
        {
          id: "generali",
          ...getDocumentsObj("GO", "generali")
        },
        {
          id: "levins",
          ...getDocumentsObj("GO", "levins")
        },
        {
          id: "ozk",
          ...getDocumentsObj("GO", "ozk")
        },
        {
          id: "uniqa",
          ...getDocumentsObj("GO", "uniqa")
        }
      ]
    },
    {
      id: "CSC",
      name: "Каско",
      active: true,
      insurers: [
        {
          id: "allianz",
          ...getDocumentsObj("CSC", "allianz")
          //    predogUrl: "" //todo
        },
        {
          id: "armeec",
          ...getDocumentsObj("CSC", "armeec")
        },
        {
          id: "bulins",
          ...getDocumentsObj("CSC", "bulins")
        },
        {
          id: "bulinsHit",
          ...getDocumentsObj("CSC", "bulins")
        },
        {
          id: "bulstrad",
          ...getDocumentsObj("CSC", "bulstrad")
        },
        {
          id: "dzi",
          ...getDocumentsObj("CSC", "dzi")
        },
        {
          id: "euroins",
          ...getDocumentsObj("CSC", "euroins")
        },
        {
          id: "generali",
          ...getDocumentsObj("CSC", "generali")
        },
        {
          id: "levins",
          ...getDocumentsObj("CSC", "levins")
        }
      ]
    },
    {
      id: "TRA",
      name: "Пътуване в чужбина",
      insurers: [
        {
          id: "bulstrad",
          ...getDocumentsObj("TRA", "bulstrad")
        },
        {
          id: "levins",
          ...getDocumentsObj("TRA", "levins")
        },
        {
          id: "euroins",
          ...getDocumentsObj("TRA", "euroins")
        },
        {
          id: "uniqa",
          ...getDocumentsObj("TRA", "uniqa")
        },
        {
          id: "allianz",
          ...getDocumentsObj("TRA", "allianz")
        },
        {
          id: "instinct",
          ...getDocumentsObj("TRA", "instinct")
        }
      ],
      active: true
    },
    {
      id: "PRP",
      name: "Имущество",
      insurers: [
        {
          id: "bulstrad",
          ...getDocumentsObj("PRP", "bulstrad")
        },
        {
          id: "uniqa",
          ...getDocumentsObj("PRP", "uniqa")
        }
      ],
      active: true
    },
    {
      id: "MTN",
      name: "Планинска",
      insurers: [
        {
          id: "uniqa",
          ...getDocumentsObj("MTN", "uniqa")
        },
        {
          id: "euroins",
          ...getDocumentsObj("MTN", "euroins")
        },
        {
          id: "bulstrad",
          ...getDocumentsObj("MTN", "bulstrad")
        }
      ],
      active: true
    },
    {
      id: "EXT",
      name: "Екстремни спортове",
      insurers: [
        {
          id: "uniqa",
          ...getDocumentsObj("EXT", "uniqa")
        },
        {
          id: "euroins",
          ...getDocumentsObj("EXT", "euroins")
        }
      ],
      active: true
    },
    {
      id: "HLT",
      name: "Здравна застраховка Уника",
      insurers: [
        {
          id: "uniqa",
          ...getDocumentsObj("HLT", "uniqa", "healthProducts")
        }
      ],
      active: true
    },
    {
      id: "LFE",
      name: "Живот",
      insurers: [
        {
          id: "metlife",
          ...getDocumentsObj("LFE", "metlife", "lifeProducts")
        }
      ],
      active: true
    },
    {
      id: "LFI",
      name: "Инвестиционна Застраховка Живот",
      insurers: [
        {
          id: "metlife",
          ...getDocumentsObj("LFI", "metlife", "lifeProducts")
        }
      ],
      active: true
    },
    {
      id: "HWP",
      name: "Здравна застраховка",
      insurers: [
        {
          id: "metlife",
          ...getDocumentsObj("HWP", "metlife", "healthProducts")
        }
      ],
      active: true
    },
    {
      id: "HCL",
      name: "Здравна застраховка Булстрад",
      insurers: [
        {
          id: "bulstrad",
          ...getDocumentsObj("HCL", "bulstrad", "healthProducts")
        }
      ],
      active: true
    },
    {
      id: "CI",
      name: "Фирмена Застраховка Живот",
      insurers: [
        {
          id: "metlife",
          ...getDocumentsObj("CI", "metlife", "companyProducts")
        }
      ],
      active: true
    },
    {
      id: "CHCL",
      name: "Фирмена здравна застраховка",
      insurers: [
        {
          id: "bulstrad",
          ...getDocumentsObj("CHCL", "bulstrad", "companyProducts")
        }
      ],
      active: true
    },
    {
      id: "HCEF",
      name: "Допълнително Здравно Булстрад",
      insurers: [
        {
          id: "bulstrad",
          ...getDocumentsObj("HCEF", "bulstrad", "companyProducts")
        }
      ],
      active: true
    },
    {
      id: "KID",
      name: "Детска",
      insurers: [
        {
          id: "dzi",
          euDocumentUrl: "https://devdiag558.blob.core.windows.net/documents/MED_A3_20190909.pdf",
          termsUrl: "https://devdiag558.blob.core.windows.net/documents/MED_OU_20191408.pdf",
          predogUrl: "https://devdiag558.blob.core.windows.net/documents/DZI_PDOG_20191104.pdf",
          helpUrl: "https://devdiag558.blob.core.windows.net/documents/BD_HELP_202002.pdf"
        }
      ],
      active: true
    },
    {
      id: "MED",
      name: 'Здравна застраховка "Лечение без граници"',
      insurers: [
        {
          id: "dzi",
          euDocumentUrl: "https://devdiag558.blob.core.windows.net/documents/MED_A3_20190909.pdf",
          predogUrl: "https://devdiag558.blob.core.windows.net/documents/DZI_PDOG_20191104.pdf",
          termsUrl: "https://devdiag558.blob.core.windows.net/documents/MED_OU_20191408.pdf",
          helpUrl: "https://devdiag558.blob.core.windows.net/documents/BD_HELP_202002.pdf"
        }
      ],
      active: true
    }
  ];

  platformTermsName = commonDocumentsConfigArray[config].platformTermsName;

  platformTermsUrl = commonDocumentsConfigArray[config].platformTermsUrl;

  privacyPolicyUrl = commonDocumentsConfigArray[config].privacyPolicyUrl;

  privacyPolicyName = commonDocumentsConfigArray[config].privacyPolicyName;

  privacyPolicy2Name = commonDocumentsConfigArray[config].privacyPolicy2Name;

  privacyPolicyUrl2 = commonDocumentsConfigArray[config].privacyPolicyUrl2;

  infoUserInsUrl = commonDocumentsConfigArray[config].infoUserInsUrl;

  vDogUrl = commonDocumentsConfigArray[config].vDogUrl;

  paymentTermsUrl = commonDocumentsConfigArray[config].paymentTermsUrl;

  ipAddress = "127.0.0.1";

  // @observable largeScreen = Dimensions.get("window").width > 700;

  getProduct(code) {
    return this.products.find(obj => obj.id === code);
  }

  getInsurer(code) {
    return this.insurers.find(obj => obj.id === code);
  }

  setIp(ip) {
    this.ipAddress = ip;
  }

  updateSettings(settings) {
    if (settings) {
      this.platformTermsUrl = settings.platformTermsUrl;
      this.privacyPolicyUrl = settings.privacyPolicyUrl;
      this.products = settings.products ? mergeByKey("id", this.products, settings.products) : this.products;
      this.insurers = settings.insurers;
    }
  }
}
const settingsStore = new SettingsStore();
export default settingsStore;
